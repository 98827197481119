
@keyframes animate-to-top-B {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(7px);
    }
    35% {
        transform: translateY(-12px);
    }
    40% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        visibility: visible;
        opacity: 1;
    }
}

@keyframes text_anime_on {
    0% {
        opacity:0;
        transform: translate(0, 30%);
    }
    100% {
        opacity:1;
        transform: translate(0, 0);
    }
}

@keyframes progressBar {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes splash-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes splash-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        pointer-events: none;
    }
}

//bgroop-home

@keyframes bgroop-home-information-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 756.9811px;
    }
}
@keyframes bgroop-home-information-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -346.06px 0;
    }
}
@keyframes bgroop-home-katsudo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 514.4151px;
    }
}
@keyframes bgroop-home-katsudo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -236.27px 0;
    }
}
@keyframes bgroop-home-shoplist-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 567.0509px;
    }
}
@keyframes bgroop-home-shoplist-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -259.41px 0;
    }
}
@keyframes bgroop-home-areaguide-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 642.2642px;
    }
}
@keyframes bgroop-home-areaguide-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -294.57px 0;
    }
}
@keyframes bgroop-home-shopnews-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 664.0296px;
    }
}
@keyframes bgroop-home-shopnews-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -303.33px 0;
    }
}
@keyframes bgroop-home-access-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 453.3184px;
    }
}
@keyframes bgroop-home-access-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -207.85px 0;
    }
}

//bgroop

@keyframes bgroop-areaguide-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -864.73px 0;
    }
}
@keyframes bgroop-areaguide-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -546.58px 0;
    }
}
@keyframes bgroop-shopinfo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1104.1576px 0;
    }
}
@keyframes bgroop-shopinfo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -924.732px 0;
    }
}
@keyframes bgroop-katsudo-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -525.2366px 0;
    }
}
@keyframes bgroop-katsudo-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -439.8857px 0;
    }
}
@keyframes bgroop-shopnews-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -673.1548px 0;
    }
}
@keyframes bgroop-shopnews-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -563.7671px 0;
    }
}
@keyframes bgroop-information-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1014.42px 0;
    }
}
@keyframes bgroop-information-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -641.1886px 0;
    }
}
@keyframes bgroop-special-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -471.5538px 0;
    }
}
@keyframes bgroop-special-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -394.9263px 0;
    }
}
@keyframes bgroop-about-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -396.9057px 0;
    }
}
@keyframes bgroop-about-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -332.4085px 0;
    }
}
@keyframes bgroop-notfound-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -962.2642px 0;
    }
}
@keyframes bgroop-notfound-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -805.8962px 0;
    }
}
@keyframes bgroop-privacy-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1197px 0;
    }
}
@keyframes bgroop-privacy-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -756.5943px 0;
    }
}
@keyframes bgroop-sitemap-pc {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -489.0633px 0;
    }
}
@keyframes bgroop-sitemap-sp {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -409.5905px 0;
    }
}

@keyframes barAnime{
    0%{
        // height: 0;
        top: -300px;
        // opacity: 0;
    }
    30%{
        // height: 100px;
        // opacity: 1;
    }
    100%{
        // height: 0;
        top: 200px;
        // opacity: 0;
    }
}

@keyframes slide1 {
    0% {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide2 {
    0% {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200%);
    }
}

@keyframes infinity-scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
