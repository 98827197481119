@use 'component/utility' as *;
@use 'component/anime' as *;

/* ====================================
reset
==================================== */

@use 'component/reset';

/* ====================================
wysiwyg
==================================== */

@use 'component/wysiwyg';

/* ====================================
common style
==================================== */
:root {
    --scrollbar: 0;
}

* {
    box-sizing: border-box;
    word-break: break-all;
}

html {
    font-size: 62.5%;
}

img, svg {
    max-width: 100%;
    height: auto;
}

a, button {
    color: inherit;
    text-decoration: none;
    @include mq(pc){
        transition: all 0.3s;
        &:hover {
            opacity: 0.6;
        }
    }
}

i {
    font-style: normal;
}

.pc-visible {
    @include mq(sp){
        display: none;
    }
}
.sp-visible {
    display: none;
    @include mq(sp){
        display: block;
    }
}

/* ====================================
common parts
==================================== */

body {
    position: relative;
    padding-top: 84px;
    background: #F8FFF2;
    color: #262626;
    font-family: $ff-n;
    font-weight: $fw-m;
    letter-spacing: 0.04em;
    line-height: 1.7;
    font-size: 1.4rem;
    @include mq(sp){
        padding-top: vw(50);
    }
}

//SVGS aspect
svg.ico-arr1 {aspect-ratio: 34/32;}
svg.ico-arr2-b {aspect-ratio: 57/32;}
svg.ico-arr2-t {aspect-ratio: 57/32;}
svg.ico-arr3-l {aspect-ratio: 18/32;}
svg.ico-arr3-r {aspect-ratio: 18/32;}
svg.ico-plus {aspect-ratio: 32/32;}
svg.ico-new {aspect-ratio: 32/32;}
svg.logo {aspect-ratio: 167/32;}
svg.txt-eventnews {aspect-ratio: 325/32;}
svg.txt-recruit {aspect-ratio: 199/32;}
svg.txt-shopguide {aspect-ratio: 290/32;}
svg.txt-shopnews {aspect-ratio: 282/32;}
svg.txt-about {aspect-ratio: 168/32;}
svg.txt-event {aspect-ratio: 315/32;}
svg.txt-facilities {aspect-ratio: 247/32;}
svg.txt-sitemap {aspect-ratio: 218/32;}

.c-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    &__main {
        display: flex;
        align-items: center;
        min-height: 84px;
        padding: 0 180px 0 40px;
        background: #fff;
        @include mq(sp){
            min-height: vw(50);
            padding: 0 vw(100) 0 vw(20);
        }
        &__title {
            width: 183px;
            height: 35px;
            @include mq(sp){
                width: vw(156.68);
                height: vw(30);
            }
            svg {
                width: 100%;
                height: 100%;
            }
        }
        &__hour {
            display: flex;
            min-width: 544px;
            margin: 0 0 0 20px;
            padding: 15px 22px;
            background: #FFFCF0;
            border-radius: 99px;
            font-weight: $fw-b;
            @include mq(sp){
                display: none;
            }
            &__title {
                color: #009342;
            }
            &__subtitle {
                margin-left: 10px;
                color: #009342;
                font-weight: $fw-bl;
                &--2 {
                    margin-left: 20px;
                }
            }
            &__txt {
                margin-left: 10px;
            }
            &__link {
                margin-left: 20px;
                color: #FF6521;
                font-size: 1.2rem;
                text-decoration: underline;
            }
        }
        &__line {
            width: 60px;
            margin: 0 0 0 auto;
            @include mq(sp){
                width: vw(40);
            }
        }
        &__menubtn {
            // position: fixed;
            position: absolute;
            top: 0;
            right: 0;
            width: 186px;
            @include mq(sp){
                width: vw(111);
            }
        }
    }
    &__menu {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        padding: 120px 0;
        background: #009342;
        @include mq(sp){
            // padding: 90px 0;

            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            height: 100%;
            // background: $pointColor2;
            padding: vw(90) vw(50);
        }
        .c-menu-close {
            // position: absolute;
            position: fixed;
            right: 20px;
            top: 17px;
            text-align: center;
            @include mq(sp){
                right: vw(20);
                top: vw(17);
            }
            &__img {
                width: 40px;
                @include mq(sp){
                    width: 25.5px;
                }
            }
            &__txt {
                margin-top: 10px;
                color: #fff;
                font-size: 19px;
                font-weight: $fw-bl;
                line-height: 1;
                @include mq(sp){
                    margin-top: vw(8.3);
                    font-size: vw(13);
                }
            }
        }
        .c-main-nav {
            // display: flex;
            // justify-content: center;
            &__list {
                display: flex;
                flex-wrap: wrap;
                max-width: 786px;
                margin: 0 auto;
                @include mq(sp){
                    flex-direction: column;
                    // margin: 0 vw(50);
                }
                li {
                    @include mq(pc){
                        width: 33%;
                        &:nth-child(3n+1) {
                            width: 252px;
                        }
                        &:nth-child(3n+2) {
                            width: 272px;
                        }
                        &:nth-child(3n+3) {
                            width: 262px;
                        }
                        &:nth-child(n+4) {
                            margin-top: 30px;
                        }
                    }
                    @include mq(sp){
                        &:nth-child(n+2) {
                            margin-top: 20px;
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-weight: $fw-b;
                        font-size: 20px;
                        letter-spacing: 0.02em;
                    }
                    img {
                        width: 60px;
                        margin-right: 20px;
                    }
                    p {

                    }
                }
            }
        }
        .c-sub-nav {
            max-width: 460px;
            margin: 40px auto 0;
            @include mq(sp){
                // margin: 20px vw(50) 0;
                margin-top: 20px;
            }
            &__list {
                display: flex;
                justify-content: space-between;
                @include mq(sp){
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                li {
                    @include mq(sp){
                        &:nth-child(2n+1) {
                            margin-right: vw(7);
                        }
                        &:nth-child(n+3) {
                            margin-top: 10px;
                        }
                    }
                    a {
                        color: #fff;
                        font-weight: $fw-b;
                        font-size: 12px;
                        text-decoration: underline;
                        letter-spacing: 0.02em;
                        @include mq(sp){
                            font-size: vw(12);
                        }
                    }
                }
            }
        }
    }
}
body,html {
    &.fixed {
        overflow: hidden;
    }
}
.c-telop {
    padding: 20px 40px;
    background: #F8F8F8;
    @include mq(sp){
        padding: vw(10) vw(20);
    }

    a {
        .c-telop__item__title, .c-telop__item__txt {
            text-decoration: underline;
        }
    }
    &__item {
        &:nth-child(n+2) {
            margin-top: 20px;
            @include mq(sp){
                margin-top: 10px;
            }
        }
        &__title {
            color: #009342;
            font-weight: $fw-b;
            @include mq(sp){
                font-size: vw(12);
            }
        }
        &__txt {
            font-size: 1.2rem;
            @include mq(sp){
                font-size: vw(12);
            }
        }
    }
}
.c-footer {
    &__access {
        position: relative;
        &__map {
            height: 405px;
            overflow: hidden;
            @include mq(sp){
                height: vw(260);
            }
            iframe {
                width: 200%;
                height: 100%;
                margin-left: -50%;
                @include mq(sp){
                    width: 300%;
                    margin-left: -100%;
                }
            }
        }
        &__info {
            position: absolute;
            left: 40px;
            top: 45px;
            width: 320px;
            height: 320px;
            padding: 72px 0 0;
            background: #fff;
            border-radius: 20px;
            text-align: center;
            @include mq(sp){
                position: static;
                width: auto;
                height: auto;
                padding: 40px 0;
                border-radius: 0;
            }
            .ft-logo {
                display: block;
                width: 209px;
                margin: 0 auto;
                @include mq(sp){
                    width:  vw(209);
                }
            }
            .address {
                margin-top: 20px;
                letter-spacing: 0.02em;
                line-height: 1;
            }
            .c-btn1 {
                width: 230px;
                margin: 20px auto 0;
                @include mq(sp){
                    width: vw(240);
                }
            }
        }
    }
    &__copy {
        padding: 23px 0;
        background: #009342;
        color: #fff;
        font-size: 1rem;
        letter-spacing: 0.02em;
        line-height: 1;
        text-align: center;
        @include mq(sp){
            font-size: 10px;
            padding: 13px 0;
        }
    }
}

.c-topicpath {
    display: flex;
    padding: 7px 40px;
    background: #FFFCF0;
    color: #005f2e;
    @include mq(sp){
        display: none;
    }
    > * {
        margin-right: 10px;
    }
}

.c-local-header {
    height: 237px;
    padding: 53px 0 0;
    background: url(../images/common/bg-cat-title-pc.png) no-repeat 50% 0 / 100% 100%;
    text-align: center;
    @include mq(sp){
        height: 167px;
        margin-top: 50px;
        padding: 50px 0 0;
        background: url(../images/common/bg-cat-title-sp.png) no-repeat 50% 0 / 100% 100%;
    }
    &__title {
        height: 43px;
        @include mq(sp){
            height: 30px;
        }
        svg {
            height: 100%;
            fill: #fff;
        }
    }
    &__subtitle {
        margin-top: 20px;
        color: #fff;
        font-size: 1.6rem;
        @include mq(sp){
        }
    }
}

.c-local-container {
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    @include mq(sp){
        margin-top: 20px;
    }
    &-in {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
    }
}

.c-select-nav {
    position: relative;
    z-index: 10;
    height: 50px;
    @include mq(sp){
        height: 40px;
    }
    &-in {
        position: absolute;
        right: 0;
        top: 0;
        width: 180px;
        // min-height: 100%;
        margin-left: auto;
        background: #fff;
        border: 2px solid #86C900;
        border-radius: 25px;
        color: #86c900;
        font-weight: $fw-b;
        letter-spacing: 0.02em;
        text-align: center;
        @include mq(sp){
            width: vw(160);
            border-radius: vw(25);
        }
    }
    &__head {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        @include mq(sp){
            height: 36px;
        }
        svg {
            position: absolute;
            right: 17px;
            top: 20px;
            width: 16px;
            fill: #86C900;
            transition: all 0.2s;
        }
        &.is-open {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__links {
        width: 160px;
        max-height: 0;
        margin: 0 auto;
        overflow: hidden;
        transition: all 0.5s;
        @include mq(sp){
            width: vw(138);
        }
        a {
            display: block;
            padding: 8px 0;
            &:nth-child(1) {
                border-top: 2px solid #86C900;
            }
            &:nth-child(n+2) {
                border-top: 1px solid #EDF5DC;
            }
        }
        &.is-open {
            max-height: var(--max-height);
        }
    }
}

.c-btn-nav {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    @include mq(sp){
        margin-top: 20px;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(184/1000*100%);
        height: 40px;
        background: #DAF5A2;
        border-radius: 99px;
        color: #009342;
        letter-spacing: 0.02em;
        @include mq(pc){
            &:not(:nth-child(5n)) {
                margin-right: calc(20/1000*100%);
            }
            &:nth-child(n+6) {
                margin-top: 20px;
            }
        }
        @include mq(sp){
            width: vw(105);
            font-size: 1.3rem;
            &:not(:nth-child(3n)) {
                margin-right: vw(10);
            }
            &:nth-child(n+4) {
                margin-top: 10px;
            }
        }
        &.current {
            background: #009342;
            color: #fff;
        }
    }
}

.c-cardlist {
    display: flex;
    flex-wrap: wrap;
    &__item {
        @include mq(pc){
            width: calc(220 / 1000 * 100%);
            &:not(:nth-child(4n)) {
                margin-right: calc(40 / 1000 * 100%);
            }
            &:nth-child(n+5) {
                margin-top: 40px;
            }
        }
        @include mq(sp){
            width: vw(162);
            &:nth-child(2n+1) {
                margin-right: vw(10);
            }
            &:nth-child(n+3) {
                margin-top: vw(20);
            }
        }
        &__in {
            position: relative;
            @include mq(pc){
                &:hover {
                    opacity: 1;
                    .photo__img {
                        transform: scale(1.2,1.2);
                    }
                }
            }
        }
    }
    .ico-new {
        z-index: 3;
        left: -18px;
        top: -21px;
        @include mq(sp){
            left: -12px;
            top: -16px;
        }
    }
    .photo {
        position: relative;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 20px;
        overflow: hidden;
        transform: translateZ(0);
        @include mq(sp){
            border-radius: vw(20);
        }
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: no-repeat 0 0 / cover;
            // border-radius: 20px;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(10px);
            }
        }
        &__img {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all 0.5s;
        }
        &__txt {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 3;
            display: inline-block;
            padding: 8px 23px;
            border-radius: 0 20px 0 0;
            background: #fff;
            color: #86c900;
            font-weight: $fw-bl;
            @include mq(sp){
                font-size: vw(11);
                border-radius: 0 vw(20) 0 0;
            }
        }
    }
    .category {
        margin-top: 8px;
        font-weight: $fw-bl;
        @include mq(sp){
            font-size: vw(13);
        }
        &--EVENT {
            color: #ea3883;
        }
        &--NEWS {
            color: #4BC3F7;
        }
        &--FLOOR {
            color: #FF6521;
        }
        // &--CATEGORY {
        //     color: #005F2E;
        // }
        &::before {
            content: '';
            display: inline-block;
            width: 10px;
            aspect-ratio: 1/1;
            margin-right: 5px;
            background: #009342;
            border-radius: 100%;
            @include mq(sp){
                width: vw(10.63);
            }
        }
    }
    .txt-l {
        margin-top: 8px;
        font-weight: $fw-b;
        letter-spacing: 0.02em;
        @include text-lc(2);
        @include mq(sp){
            margin-top: 4px;
            font-size: vw(12);
        }
    }
    .txt-s {
        margin-top: 3px;
        font-size: 1.2rem;
        letter-spacing: 0.02em;
        @include text-lc(2);
        @include mq(sp){
            margin-top: 2px;
            font-size: vw(10);
        }
    }
    .date {
        margin-top: 3px;
        color: #86c900;
        font-weight: $fw-b;
        @include mq(sp){
            margin-top: 2px;
            font-size: vw(11);
        }
    }
    &--shopnews {
        .photo__img {
            object-fit: cover;
        }
    }
}

.c-entry {
    padding: 90px;
    background: #fff;
    border-radius: 50px;
    @include mq(sp){
        padding: 30px 20px;
        border-radius: 30px;
    }
    &__header {
        padding-bottom: 40px;
        border-bottom: 2px solid #EDF5DC;
        @include mq(sp){
            padding-bottom: 30px;
        }
        &__meta {
            display: flex;
            font-size: 2.0rem;
            @include mq(sp){
                flex-direction: column;
                // font-size: vw(20);
            }
            .category {
                margin-right: 20px;
                font-weight: $fw-bl;
                &--EVENT {
                    color: #ea3883;
                }
                &--NEWS {
                    color: #4BC3F7;
                }
                &--FLOOR {
                    color: #FF6521;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    aspect-ratio: 1/1;
                    margin-right: 10px;
                    background: #009342;
                    border-radius: 100%;
                    @include mq(sp){
                        width: vw(16);
                    }
                }
            }
            .date {
                color: #86c900;
                font-weight: $fw-b;
            }
        }
        &__title {
            margin-top: 20px;
            font-weight: $fw-bl;
            font-size: 2.2rem;
            letter-spacing: 0.02em;
            line-height: calc(30/22);
            @include mq(sp){
                margin-top: 15px;
                font-size: 1.8rem;
            }
        }
        &__place {
            margin-top: 20px;
            @include mq(sp){
                margin-top: 15px;
            }
            span {
                display: inline-block;
                padding: 13px 16px;
                background: #F8FFF2;
                border-radius: 99px;
                font-size: 1.6rem;
                letter-spacing: 0.02em;
                line-height: 1;
                @include mq(sp){
                    // padding: ;
                    font-size: 1.4rem;
                }
            }
        }
    }
    &__pic {
        margin-top: 40px;
        @include mq(sp){
            margin-top: 30px;
        }
    }
    &__body {
        margin-top: 40px;
        @include mq(sp){
            margin-top: 30px;
        }
    }
}

.c-sns-share {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    @include mq(sp){
        margin-top: 30px;
    }
    &__title {
        margin: 0 5px;
        color: #009342;
        font-weight: $fw-b;
        letter-spacing: 0.02em;
    }
    &__link {
        margin: 0 5px;
    }
}

.c-entry-nav {
    position: relative;
    margin-top: 30px;
    @include mq(sp){
        margin-top: 20px;
    }
    &__txtlinks {
        position: relative;
        @include mq(sp){
            display: flex;
            justify-content: space-between;
        }
        a {
            position: absolute;
            top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95px;
            color: #009342;
            font-weight: $fw-b;
            letter-spacing: 0.02;
            @include mq(sp){
                position: static;
                width: 84px;
            }
            svg {
                fill: #009342;
                width: 9px;

            }
        }
        &__prev {
            left: 0;
            margin-right: auto;
        }
        &__next {
            right: 0;
            margin-left: auto;
        }
    }
    .c-btn1 {
        width: 220px;
        margin: 0 auto;
        @include mq(sp){
            width: 180px;
            margin: 20px auto 0;
        }
    }
}

.c-shop-container {
    margin: 50px 0 0;
    padding: 110px 0 100px;
    background: #fff url(../images/shopguide/bg-shoplist-pc.png) no-repeat 0 0 / 100% 37px;
    @include mq(sp){
        padding: 64px 20px 100px;
        background: #fff url(../images/shopguide/bg-shoplist-sp.png) no-repeat 0 0 / 100% 15px;
    }
    &.shopnews {

    }
    &-in {
        max-width: 1000px;
        margin: 0 auto;
    }
    &__title {
        margin-bottom: 40px;
        color: #005f2e;
        font-weight: $fw-b;
        font-size: 2.6rem;
        letter-spacing: 0.02em;
        text-align: center;
        @include mq(sp){
            font-size: 2rem;
        }
    }
    .c-cardlist--shoplist {
        .photo {
            border: 2px solid #DAF5A2;
            &__txt {
                width: 84px;
                background: #DAF5A2;
                color: #86c900;
                text-align: center;
            }
        }
    }
}

.c-box {
    padding: 90px;
    background: #fff;
    border-radius: 50px;
    @include mq(sp){
        padding: 30px 20px;
        border-radius: 30px;
    }
}

.c-tb1 {
    width: 100%;
    line-height: calc(24/14);
    @include mq(sp){
        display: block;
        line-height: calc(20/14);
    }
    tr {
        @include mq(sp){
            display: block;
        }
    }
    th, td {
        padding: 10px 20px;
        border-bottom: 2px solid #fff;
        vertical-align: middle;
        @include mq(sp){
            display: block;
            border-bottom-width: 10px;
        }
    }
    th {
        width: 200px;
        background: #EDF5DC;
        color: #005f2e;
        font-weight: $fw-b;
        text-align: center;
        @include mq(sp){
            width: 100%;
            border: none;
        }
    }
    td {
        background: #FAFAFA;
    }
    .txt1 {
        &:nth-child(n+2) {
            margin-top: 15px;
            @include mq(sp){
                margin-top: 10px;
            }
        }
    }
    .txt-note1 {
        font-size: 12px;
        line-height: calc(20/14);
        &:nth-child(n+2) {
            margin-top: 8px;
            @include mq(sp){
                margin-top: 10px;
                // margin-top: 3px;
            }
        }
    }
}

.c-error-txt {
    font-size: 2rem;
    text-align: center;
}

/* ====================================
parts
==================================== */
.ico-new {
    position: absolute;
    width: 60px;
    @include mq(sp){
        width: vw(50);
    }
}
.c-btn1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background: #86C900;
    border-radius: 999px;
    color: #fff;
    font-weight: $fw-b;
    font-size: 1.6rem;
    letter-spacing: 0.02em;
    @include mq(pc){
        transition: all 0.3s;
        &:hover {
            opacity: 1;
            background: #FF6521;
            .ico-arr1 {
                transform: translate(40%, -50%);
            }
        }
    }
    @include mq(sp){
        height: vw(70);
        font-size: vw(16);
    }
    .ico-arr1 {
        position: absolute;
        right: 29px;
        top: 50%;
        width: 17px;
        fill: #fff;
        transform: translateY(-50%);
        transition: all 0.3s;
        @include mq(sp){
            right: vw(19);
            width: vw(17);
        }
    }
    .ico-plus {
        position: absolute;
        right: 29px;
        top: 50%;
        width: 17px;
        fill: #fff;
        transform: translateY(-50%);
        @include mq(sp){
            right: vw(19);
            width: vw(17);
        }
    }
}
.c-txt-link {
    text-decoration: underline;
}

.c-dark-green {
    color: #005f2e;
}
.c-green {
    color: #009342;
}
.c-orange {
    color: #FF6521;
}

//
// animation
//////////////////////////////////////////////////////////////////////
// scroll
.js-scrollAnim, .js-scrollAnimList {
    opacity: 0;
    transform: translateY(20px);
    // animation-duration: .3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    // transition: all 2s;
    // &.c-btn1 {
    //     transition: all 0.3s, transform 2s;
    // }
    &.is-show {
        animation-name: animate-to-top-B;
        animation-duration: .4s;
        // opacity: 1;
        // transform: translateY(0px);
    }
}
// .c-logo-pc {
//     position: absolute;
//     left: vwPC(121);
//     top: vwPC(31);
//     width: vwPC(208.26);
//     aspect-ratio: 173/32;
//     z-index: 400;
//     line-height: 0;
//     @include mq(sp){
//         display: none;
//     }
//     svg {
//         width: 100%;
//         height: 100%;
//     }
// }

// .c-header {
//     position: fixed;
//     z-index: 1000;
//     &__title {
//         position: fixed;
//         left: vwPC(121);
//         top: vwPC(31);
//         z-index: 400;
//         line-height: 0;
//         @include mq(pc){
//             display: none;
//         }
//         @include mq(sp){
//             left: vw(15);
//             top: vw(15);
//         }
//         .logo {
//             width: vwPC(208.26);
//             aspect-ratio: 173/32;
//             @include mq(sp){
//                 width: vw(107.41);
//             }
//         }
//     }
//     &__bar {
//         position: fixed;
//         left: 0;
//         top: 0;
//         z-index: 300;
//         width: vwPC(91);
//         height: 100vh;
//         background: #fff;
//         border-right: 2px solid #E8E8E8;
//         @include mq(sp){
//             width: 100%;
//             height: vw(50);
//             border-right: none;
//             border-bottom: 1px solid #E8E8E8;
//         }
//         &__menubtn {
//             position: absolute;
//             @include mq(pc){
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//             }
//             @include mq(sp){
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 width: vw(71.5);
//                 right: vw(15);
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//             &__txt {
//                 font-family: $ff-o;
//                 font-weight: $fw-m;
//                 font-size: vwPC(12);
//                 text-align: center;
//                 @include mq(sp){
//                     font-size: vw(12);
//                 }
//             }
//             &__elm {
//                 position: relative;
//                 width: vwPC(32);
//                 height: 10px; //vwだと汚くなる
//                 margin-top: vwPC(8);
//                 @include mq(sp){
//                     width: vw(32);
//                     height: vw(10); //spはvwでいい
//                     margin-top: 0;
//                 }
//                 .bar {
//                     position: absolute;
//                     left: 0;
//                     display: block;
//                     height: 2px; //vwだと汚くなる
//                     background: #000;
//                     transition: all 0.2s;
//                     @include mq(sp){
//                         height: vw(2);//spはvwでいい
//                     }
//                     &--1 {
//                         top: 0;
//                         width: 100%;
//                     }
//                     &--2 {
//                         bottom: 0;
//                         width: vwPC(24);
//                         @include mq(sp){
//                             width: vw(24);
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &__menu {
//         // display: none;
//         position: fixed;
//         top: 0;
//         left: vwPC(91);
//         width: 100%;
//         height: 100vh;
//         z-index: 200;
//         pointer-events: none;
//         @include mq(sp){
//             top: vw(50);
//             left: 0;
//             height: calc(100vh - #{vw(50)});
//         }

//         &__bg {
//             position: fixed;
//             top: 0;
//             left: vwPC(91);
//             z-index: 210;
//             width: calc(100% - #{vwPC(91)});
//             height: 100vh;
//             backdrop-filter: blur(15px);
//             transition: all 0.3s;
//             pointer-events: none;
//             opacity: 0;
//             @include mq(sp){
//                 top: vw(50);
//                 left: 0;
//                 width: 100%;
//                 height: calc(100vh - #{vw(50)});
//             }
//         }
//         &__main {
//             position: fixed;
//             top: 0;
//             left: vwPC(91);
//             z-index: 220;
//             width: vwPC(609);
//             height: 100vh;
//             padding: vwPC(114) 0 0;
//             background: #fff;
//             overflow-y: auto;
//             transition: all 0.3s;
//             pointer-events: none;
//             opacity: 0;
//             @include mq(sp){
//                 top: vw(50);
//                 left: 0;
//                 width: 100%;
//                 height: calc(100vh - #{vw(50)});
//                 padding: vw(14) 0 0;
//             }
//             .c-logo-pc {
//                 left: vwPC(30);
//                 top: vwPC(31);
//             }
//             &__sec {
//                 padding: vwPC(24) vwPC(74);
//                 @include mq(sp){
//                     padding: vw(24) vw(27);
//                 }
//                 &:nth-of-type(n+2) {
//                     border-top: 1px solid #E8E8E8;
//                 }
//                 &--1 {
//                     padding-top: 0;
//                 }
//                 &--2 {
//                 }
//                 &--3 {
//                     @include mq(pc){
//                         padding-top: vwPC(30);
//                         padding-bottom: vwPC(30);
//                     }
//                     @include mq(sp){
//                     }
//                 }
//                 &--4 {
//                 }
//                 &__title {
//                     font-size: vwPC(16);
//                     font-weight: $fw-m;
//                     @include mq(sp){
//                         font-size: vw(16);
//                     }
//                 }
//                 .c-menu-nav {
//                     &__list {
//                         > li {
//                             &:nth-child(n+2) {
//                                 margin-top: vwPC(22);
//                                 @include mq(sp){
//                                     margin-top: vw(22);
//                                 }
//                             }
//                             > a {
//                                 display: flex;
//                                 align-items: center;
//                                 .txt-en {
//                                     font-family: $ff-o;
//                                     font-size: vwPC(30);
//                                     line-height: calc(55/30);
//                                     @include mq(sp){
//                                         font-size: vw(30);
//                                     }
//                                 }
//                                 .txt-ja {
//                                     margin-left: vwPC(16);
//                                     color: #7d7d7d;
//                                     font-size: vwPC(12);
//                                     line-height: calc(55/12);
//                                     @include mq(sp){
//                                         margin-left: vw(16);
//                                         font-size: vw(12);
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .c-menu-nav-child {
//                         margin-top: vwPC(17);
//                         @include mq(sp){
//                             margin-top: vw(17);
//                         }
//                         > li {
//                             position: relative;
//                             display: flex;
//                             &:nth-child(n+2) {
//                                 margin-top: vwPC(16);
//                                 @include mq(sp){
//                                     margin-top: vw(16);
//                                 }
//                             }
//                             &::before {
//                                 content: '';
//                                 display: block;
//                                 position: relative;
//                                 left: 0;
//                                 top: vwPC(7);
//                                 width: vwPC(10);
//                                 height: vwPC(2);
//                                 min-height: 2px;
//                                 background: #7D7D7D;
//                                 @include mq(sp){
//                                     top: vw(7);
//                                     width: vw(10);
//                                     height: vw(2);
//                                 }
//                             }
//                             > a {
//                                 position: relative;
//                                 margin-left: vwPC(13);
//                                 font-size: vwPC(13);
//                                 line-height: calc(20/13);
//                                 @include mq(sp){
//                                     margin-left: vw(13);
//                                     font-size: vw(13);
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .c-menu-taglist {
//                     display: flex;
//                     flex-wrap: wrap;
//                     margin: vwPC(15) vwPC(-5) 0;
//                     @include mq(sp){
//                         margin: vw(15) vw(-5) 0;
//                     }
//                     li {
//                         margin: vwPC(5);
//                         @include mq(sp){
//                             margin: vw(5);
//                         }
//                     }
//                 }
//                 .c-header-otherlink {
//                     display: flex;
//                     flex-wrap: wrap;
//                     li {
//                         width: 50%;
//                         &:nth-child(n+3) {
//                             margin-top: vwPC(14);
//                             @include mq(sp){
//                                 margin-top: vw(14);
//                             }
//                         }
//                         a {
//                             font-size: vwPC(13);
//                             @include mq(sp){
//                                 font-size: vw(13);
//                             }
//                         }
//                     }
//                 }
//                 .c-header-corplink {
//                     font-size: vwPC(13);
//                     font-weight: $fw-b;
//                     @include mq(sp){
//                         font-size: vw(13);
//                     }
//                 }
//                 .c-viewmore-wrapper {
//                     margin: vwPC(20) 0 0;
//                     @include mq(sp){
//                         margin: vw(20) 0 0;
//                     }
//                 }
//             }
//         }
//     }
//     &.open {
//         .c-header__menu, .c-header__menu__bg, .c-header__menu__main {
//             pointer-events: initial;
//             opacity: 1;
//         }
//         .c-header__bar__menubtn__elm {
//             .bar--1 {
//                 top: 50%;
//                 transform: translateY(-50%) rotate(15deg);
//             }
//             .bar--2 {
//                 width: 100%;
//                 top: 50%;
//                 transform: translateY(-50%)  rotate(-15deg);
//             }
//         }
//     }

// }

// .c-pagetop {
//     display: inline-block;
//     position: fixed;
//     right: vwPC(16);
//     bottom: vwPC(16);
//     z-index: 20;
// }
// .c-footer {
//     position: relative;
//     z-index: 10;
//     margin-left: vwPC(91);
//     background: #fff;
//     @include mq(sp){
//         margin-left: 0;
//     }
//     &__toplink {
//         padding: vwPC(60) 0;
//         border-bottom: 2px solid #F1F1F1;
//         @include mq(sp){
//             padding: vw(30) 0;
//         }
//         a {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             width: vwPC(168);
//             margin: 0 auto;
//             @include mq(sp){
//                 width: vw(168);
//             }
//             .c-arr1 {
//                 width: vwPC(64);
//                 @include mq(sp){
//                     width: vw(64);
//                 }
//             }
//             .link-text {
//                 font-family: $ff-o;
//                 font-size: vwPC(20);
//                 font-weight: $fw-l;
//                 letter-spacing: 0.08em;
//                 @include mq(sp){
//                     font-size: vw(20);
//                 }
//             }
//         }
//     }
//     &__bnr {
//         padding: vwPC(80) 0 0;
//         @include mq(sp){
//             padding: vw(25.5) vw(27) 0;
//         }
//         &-in {
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: space-between;
//             width: vwPC(960);
//             margin: 0 auto;
//             @include mq(sp){
//                 flex-direction: column;
//                 width: auto;
//             }
//             a {
//                 @include mq(pc){
//                     width: vwPC(444);
//                     &:nth-child(2n+1) {
//                         // margin-right: vwPC(73);
//                     }
//                     &:nth-child(n+3) {
//                         margin-top: vwPC(40);
//                     }
//                 }
//                 @include mq(sp){
//                     &:nth-child(n+2) {
//                         margin-top: vw(10);
//                     }
//                 }
//             }
//         }
//     }
//     &__bottom {
//         margin-top: vwPC(92);
//         @include mq(sp){
//             margin-top: vw(30);
//         }
//         &__logo {
//             display: block;
//             width: vwPC(217);
//             margin: 0 auto;
//             @include mq(sp){
//                 width: vw(117);
//             }
//         }
//         &__nav {
//             margin: vwPC(12) auto 0;
//             padding: vwPC(60) vwPC(20);
//             background: #333;
//             @include mq(sp){
//                 margin: vw(20) auto 0;
//                 padding: 0;
//             }
//             &__list {
//                 @include mq(pc){
//                     width: vwPC(740);
//                     margin: 0 0 0 vwPC(296);
//                     display: flex;
//                     flex-wrap: wrap;
//                     li {
//                         width: vwPC(222);
//                         &:nth-child(3n+1) {
//                             width: vwPC(296);
//                         }
//                         &:nth-child(n+4) {
//                             margin-top: vwPC(40);
//                         }
//                         a {
//                             display: inline-block;
//                             border-bottom: 3px solid #AC1757;
//                             color: #fff;
//                             font-size: vwPC(16);
//                             font-weight: $fw-m;
//                             line-height: calc(28/16);
//                         }
//                     }
//                 }
//                 @include mq(sp){
//                     display: flex;
//                     flex-wrap: wrap;
//                     li {
//                         width: calc((100% - 2px) / 3);
//                         border-bottom: 1px solid #fff;
//                         &:not(:nth-child(3n+3)) {
//                             border-right: 1px solid #fff;
//                         }
//                         a {
//                             display: flex;
//                             align-items: center;
//                             height: 100%;
//                             padding: vw(7) vw(5);
//                             color: #fff;
//                             font-size: vw(11);
//                             font-weight: $fw-m;
//                             line-height: calc(28/16);
//                         }
//                     }
//                 }
//             }
//         }
//         &__copy {
//             padding: vwPC(10);
//             background: #AC1757;
//             color: #fff;
//             font-size: vwPC(14);
//             font-weight: $fw-m;
//             text-align: center;
//             @include mq(sp){
//                 padding: vw(10);
//                 font-size: vw(10);
//             }
//         }
//     }
// }

// .c-viewmore-wrapper {
//     display: flex;
//     &--r {
//         justify-content: flex-end;
//     }
// }
// .c-viewmore {
//     display: inline-flex;
//     align-items: center;
//     &__txt {
//         margin-right: vwPC(16);
//         font-family: $ff-o;
//         font-size: vwPC(16);
//         font-weight: $fw-l;
//         letter-spacing: 0.08em;
//         // line-height: calc(55/16);
//         line-height: 1;
//         @include mq(sp){
//             margin-right: vw(16);
//             font-size: vw(16);
//         }
//     }
// }

// .c-taglink {
//     display: inline-block;
//     padding: vwPC(5) vwPC(17);
//     border: 1px solid;
//     border-radius: 999px;
//     color: #FD6565;
//     font-size: vwPC(14);
//     @include mq(sp){
//         padding: vw(5) vw(17);
//         font-size: vw(12);
//     }
// }

// .c-linkbox {
//     display: flex;
//     justify-content: center;
//     padding: vwPC(60) 0;
//     border-bottom: 2px solid #F1F1F1;
//     @include mq(sp){
//         padding: vw(30) 0;
//     }
//     a {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         // width: vwPC(168);
//         margin: 0 auto;
//         @include mq(sp){
//             // width: vw(168);
//         }
//         .c-arr1 {
//             width: vwPC(64);
//             margin-right: vwPC(24);
//             @include mq(sp){
//                 width: vw(64);
//                 margin-right: vw(24);
//             }
//         }
//         .link-text {
//             font-family: $ff-o;
//             font-size: vwPC(20);
//             font-weight: $fw-l;
//             letter-spacing: 0.08em;
//             @include mq(sp){
//                 font-size: vw(20);
//             }
//         }
//     }
// }

// .c-arr1 {
//     position: relative;
//     display: inline-block;
//     width: vwPC(45);
//     aspect-ratio: 1/1;
//     border: 2px solid;
//     color: #000;
//     border-radius: 999px;
//     @include mq(sp){
//         width: vw(33);
//     }
//     &::after {
//         content: '';
//         display: block;
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         width: calc(9/45*100%);
//         // height: vwPC(8);
//         aspect-ratio: 1/1;
//         border-top: 2px solid;
//         border-right: 2px solid;
//         transform: translate(-62%, -50%) rotate(45deg);
//         @include mq(sp){
//             width: calc(6/33*100%);
//             // height: vw(5);
//         }
//     }
//     &--l {
//         transform: rotate(180deg);
//     }
//     &--u {
//         transform: rotate(-90deg);
//     }
//     &--d {
//         transform: rotate(90deg);
//     }
// }

// .c-breadcrumbs {
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
//     li {
//         display: flex;
//         align-items: center;
//         position: relative;
//         color: #959595;
//         font-size: vwPC(14);
//         @include mq(sp){
//             font-size: vw(11);
//         }
//         &:nth-child(n+2) {
//             &::before {
//                 content: '';
//                 display: block;
//                 // position: absolute;
//                 // left: 50%;
//                 // top: 50%;
//                 width: vwPC(6);
//                 // height: vwPC(8);
//                 margin: 0 vwPC(16);
//                 aspect-ratio: 1/1;
//                 border-top: 2px solid #000;
//                 border-right: 2px solid #000;
//                 // transform: translate(-62%, -50%) rotate(45deg);
//                 transform: rotate(45deg);
//                 @include mq(sp){
//                     width: vw(6);
//                     margin: 0 vw(16);
//                 }
//             }
//         }
//         a {
//             color: #000;
//         }
//     }
// }

// .c-pager {
//     display: flex;
//     justify-content: center;
//     margin: vwPC(80) 0 0;
//     @include mq(sp){
//         margin: vwPC(80) 0 0;
//     }
//     &-in {
//         position: relative;
//         padding: 0 vwPC(51);
//         @include mq(sp){
//             padding: 0 vw(51);
//         }
//     }
//     &__nav {
//         position: absolute;
//         top: vwPC(-6) ;
//         width: vwPC(35);
//         @include mq(sp){
//             top: vw(-6) ;
//             width: vw(35);
//         }
//         i {
//             width: 100%;
//         }
//         &--prev {
//             left: 0;
//             // transform: rotate(180deg);
//         }
//         &--next {
//             right: 0;
//         }
//     }
//     &__nums {
//         display: flex;
//         border-bottom: 1px solid #000;
//         &__item {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             position: relative;
//             width: vwPC(60);
//             padding-bottom: vwPC(16);
//             font-family: $ff-o;
//             font-weight: $fw-l;
//             line-height: vwPC(24);
//             text-align: center;
//             @include mq(sp){
//                 width: vw(40);
//                 padding-bottom: vw(16);
//                 font-size: vw(16);
//                 line-height: vw(24);
//             }
//             &.current {
//                 &::after {
//                     content: '';
//                     display: block;
//                     width: vwPC(50);
//                     height: vwPC(3);
//                     background: #000;
//                     position: absolute;
//                     bottom: 0;
//                     left: 50%;
//                     transform: translateX(-50%);
//                     @include mq(sp){
//                         width: vw(50);
//                         height: vw(3);
//                     }
//                 }
//             }
//             &.separator {
//                 @include mq(sp){
//                     margin: 0 vw(14);
//                 }
//                 &::after {
//                     content: '';
//                     display: block;
//                     width: vwPC(21.64);
//                     aspect-ratio: 22/2;
//                     background: url(../images/common/pager-elm.svg) no-repeat 50% 50% / contain;
//                     @include mq(sp){
//                         width: vw(21.64);
//                     }
//                 }
//             }
//         }
//     }
//     &-pager-elm {
//         width: vwPC(21.64);
//         @include mq(sp){
//             width: vw(21.64);
//         }
//     }
// }
// .c-ornament-txt1 {
//     position: absolute;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     background-size: 100% auto;
//     background-repeat: repeat-y;
//     animation: scroll-anim linear infinite;
//     // &::after {
//     //     content: '';
//     //     position: fixed;
//     //     right: vwPC(28);
//     //     top: 50%;
//     //     z-index: 100;
//     //     transform: translateY(-50%);
//     //     display: block;
//     //     width: vwPC(10.07);
//     //     height: vwPC(157.36);
//     //     background: url(../images/common/ornament-txt-owndmedia.svg) no-repeat 0 0 / contain;
//     //     // mix-blend-mode: color-burn;
//     //     @include mq(sp){
//     //         display: none;
//     //     }
//     // }
// }
// .c-ornament-txt2 {
//     position: fixed;
//     right: vwPC(28);
//     top: 50%;
//     z-index: 100;
//     transform: translateY(-50%);
//     display: block;
//     width: vwPC(10.07);
//     height: vwPC(157.36);
//     background: url(../images/common/ornament-txt-owndmedia.svg) no-repeat 0 0 / contain;
//     // mix-blend-mode: color-burn;
//     z-index: 1000;
//     @include mq(sp){
//         display: none;
//     }
// }
// .c-main {
//     position: relative;
//     z-index: 10;

//     &-ornament2 {
//         content: '';
//         position: fixed;
//         right: vwPC(28);
//         top: 50%;
//         z-index: 100;
//         transform: translateY(-50%);
//         display: block;
//         width: vwPC(10.07);
//         height: vwPC(157.36);
//         background: url(../images/common/ornament-txt-owndmedia.svg) no-repeat 0 0 / contain;
//         @include mq(sp){
//             display: none;
//         }
//     }
// }

// .frl__list {
//     display: flex;
//     flex-wrap: wrap;
//     margin: vwPC(50) 0 0 0;
//     @include mq(sp){
//         // margin: vw(24) 0 0 0;
//         margin: vw(24) vw(12) 0;
//     }
//     &__item {
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         @include mq(pc){
//             width: vwPC(264);
//             &:not(:nth-child(4n+4)) {
//                 margin-right: vwPC(18);
//             }
//             &:nth-child(n+5) {
//                 margin-top: vwPC(40);
//             }
//         }
//         @include mq(sp){
//             width: vw(170);
//             &:nth-child(2n+1) {
//                 margin-right: vw(10);
//             }
//             &:nth-child(n+3) {
//                 margin-top: vw(20);
//             }
//         }
//         .link {
//             display: flex;
//             flex: 1;
//             justify-content: space-between;
//             flex-direction: row-reverse;
//             @include mq(sp){
//                 flex-direction: column;
//             }
//             &:hover {
//                 @include mq(pc){
//                     opacity: 1;
//                     .cont__main {
//                         opacity: 0.5;
//                     }
//                 }
//             }
//         }
//         .date {
//             color: #fd6565;
//             font-family: $ff-o;
//             @include mq(pc){
//                 line-height: 1;
//                 writing-mode: vertical-rl;
//             }
//             @include mq(sp){
//                 font-size: vw(14);
//             }
//         }
//         .cont {
//             // display: flex;
//             width: vwPC(242);
//             background: #fff;
//             @include mq(sp){
//                 flex: 1;
//                 width: auto;
//             }
//             &__pic {
//                 overflow: hidden;
//                 img {
//                     width: 100%;
//                 }
//             }
//             &__main {
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
//                 flex: 1;
//                 padding: vwPC(14) vwPC(20);
//                 transition: all 0.3s;
//                 @include mq(sp){
//                     padding: vw(15);
//                 }
//                 .entry-title {
//                     font-size: vwPC(18);
//                     @include text-lc(3);
//                     @include mq(sp){
//                         font-size: vw(14);
//                         line-height: calc(22/14);
//                     }
//                 }
//                 .taglist {
//                     display: flex;
//                     flex-wrap: wrap;
//                     margin: vwPC(10) vwPC(-8) 0;
//                     @include mq(sp){
//                         margin: vw(8) vw(-8) 0;
//                     }
//                     span {
//                         margin: vwPC(5) vwPC(8);
//                         color: #898989;
//                         line-height: 1;
//                         font-size: vwPC(11);
//                         @include mq(sp){
//                             margin: vw(5) vw(8);
//                             font-size: vw(12);
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// .spc__list {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     margin: vwPC(50) 0 0 0;
//     @include mq(sp){
//         margin: vw(24) vw(27) 0;
//     }
//     &__item {
//         position: relative;
//         width: vwPC(535);
//         @include mq(pc){
//             &:nth-child(2n+1) {
//                 // margin-right: vwPC(40);
//             }
//             &:nth-child(n+3) {
//                 margin-top: vwPC(20);
//             }
//         }
//         @include mq(sp){
//             width: auto;
//             &:nth-child(n+2) {
//                 margin-top: vw(14);
//             }
//         }
//         .link {
//             display: block;
//             &:hover {
//                 @include mq(pc){
//                     opacity: 1;
//                     .cont__main {
//                         opacity: 0.5;
//                     }
//                 }
//             }
//         }
//         .date {
//             color: #fd6565;
//             font-family: $ff-o;
//             @include mq(sp){
//                 font-size: vw(14);
//             }
//         }
//         .cont {
//             display: flex;
//             background: #fff;
//             &__pic {
//                 width: vwPC(242);
//                 overflow: hidden;
//                 @include mq(sp){
//                     width: vw(140);
//                 }
//                 img {
//                     width: 100%;
//                     opacity: 0;
//                     transform: scale(1.4);
//                     transition: all 2s cubic-bezier(.165,.84,.44,1);
//                 }
//             }
//             &__main {
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
//                 flex: 1;
//                 padding: vwPC(30);
//                 transition: all 0.3s;
//                 @include mq(sp){
//                     padding: vw(15) vw(20);
//                 }
//                 .entry-title {
//                     font-size: vwPC(18);
//                     @include text-lc(3);
//                     @include mq(sp){
//                         font-size: vw(14);
//                         line-height: calc(23/14);
//                     }
//                 }
//                 .c-viewmore {
//                     margin-left: auto;
//                     .c-viewmore__txt {
//                         @include mq(sp){
//                             font-size: vw(12);
//                         }
//                     }
//                 }
//             }
//         }

//         // &.art-scroll-in {
//         //     opacity: 1;
//         //     .cont__pic img {
//         //         opacity: 1;
//         //         transform: scale(1);
//         //     }
//         //     .link {
//         //         &:hover {
//         //             @include mq(pc){
//         //                 .cont__pic img {
//         //                     transform: scale(1.2);
//         //                 }
//         //             }
//         //         }
//         //     }
//         // }
//     }
// }


// .fade-in {
//     opacity: 0;
//     transition-duration: 1.5s;
//     transition-property: opacity, transform;
// }
// .fade-in-up {
//     transform: translate(0, 50px);
// }
// .fade-in-down {
//     transform: translate(0, -50px);
// }
// .fade-in-left {
//     transform: translate(-50px, 0);
// }
// .fade-in-right {
//     transform: translate(50px, 0);
// }
// .scroll-in {
//     &.fade-in {
//         opacity: 1;
//         transform: translate(0, 0);
//     }
// }

// .js-eachTextAnime {
//     span{
//         display: inline-block;
//         opacity: 0;
//         transform: translate(0, 30%);
//     }
//     &.appeartext {
//         span{
//             animation: text_anime_on 1s ease-out forwards;
//         }
//     }
// }
// .js-eachTextAnime-home {
//     span{
//         display: inline-block;
//         opacity: 0;
//         transform: translate(0, 30%);
//     }
//     &.appeartext {
//         span{
//             animation: text_anime_on 0.5s ease-out forwards;
//         }
//     }
// }

// .js-art-scradd {
//     a {
//         overflow: hidden;
//     }
//     img {
//         opacity: 0;
//         transform: scale(1.4);
//         transition: all 2s cubic-bezier(.165,.84,.44,1);
//     }
//     &.art-scroll-in {
//         a {
//             &:hover {
//                 @include mq(pc){
//                     img {
//                         transform: scale(1.2);
//                     }
//                 }
//             }
//         }
//         img {
//             opacity: 1;
//             transform: scale(1);
//         }
//     }
// }
